import PageNotFound from "@goalteller/app-kit/components/PageNotFound";
import HeadWrapper from "@goalteller/app-kit/components/head/NxHeadWrapper";
import { ErrorpageButton as ErrorButton } from "@goalteller/app-kit/components/wrappers/button";
import { useRouter } from "next/router";

export default function ErrorPage() {
  const router = useRouter();
  return (
    <>
      <HeadWrapper title="Page not found - GoalTeller" />
      <PageNotFound
        backButton={
          <>
            Head over to{" "}
            <ErrorButton
              onClick={() => void router.push("/")}
              variant="borderless"
            >
              goalteller.com
            </ErrorButton>{" "}
            to choose a new direction.
          </>
        }
      />
    </>
  );
}
